import { observer } from 'mobx-react-lite';
import { Divider, Grid, Header, Item, Segment, Statistic } from 'semantic-ui-react';
import { Profile, ProfileSubscriptions } from '../../app/models/profile';

interface Props {
    profile: Profile;
    profileSubscriptions: ProfileSubscriptions | null;
}

export default observer(function ProfileHeader({profile, profileSubscriptions}: Props) {

    return (
        <Segment>
            <Grid>
                <Grid.Column width={11}>
                    <Item.Group>
                        <Item>
                            <Item.Image avatar size='small' src={profile?.image || '/assets/user.png'} />
                            <Item.Content verticalAlign='middle'>
                                <Header as='h1' content={profile?.displayName || 'Celé meno'} />
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column width={5}>
                    <Statistic.Group widths={3}>
                        <Statistic label='Autor' value={profileSubscriptions?.author} />
                        <Statistic label='Prihlásené' value={profileSubscriptions?.subscribed} />
                        <Statistic label='Neprihlásené' value={profileSubscriptions?.notSubscribed} />
                    </Statistic.Group>
                    <Divider />
                    {/* <Reveal animated='move'>
                        <Reveal.Content visible style={{width: '100%'}}>
                            <Button fluid color='teal' content='Prihlasené'/>
                        </Reveal.Content>
                        <Reveal.Content hidden style={{width: '100%'}}>
                            <Button
                                fluid
                                basic
                                color={true ? 'red' : 'green'}
                                content={true ? 'Odhlásiť' : 'Prihlásiť'}
                            />
                        </Reveal.Content>
                    </Reveal> */}
                </Grid.Column>
            </Grid>
        </Segment>
    )
})