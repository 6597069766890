import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Header, Segment } from 'semantic-ui-react';
import LoadingComponent from '../../../app/layouts/LoadingComponent';
import { Podcast } from '../../../app/models/podcast';
import { useStore } from '../../../app/stores/store';
import {v4 as uuid} from 'uuid';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../../app/common/form/MyTextInput';
import MyTextArea from '../../../app/common/form/MyTextArea';
import MySelectInput from '../../../app/common/form/MySelectInput';
import { categoryOptions } from '../../../app/common/options/categoryOptions';

export default observer(function PodcastForm() {
    const history = useHistory();
    const {podcastStore, userStore} = useStore();
    const {loadingInitial, loadPodcast, updatePodcast, createPodcast} = podcastStore;
    const {id} = useParams<{id: string}>();

    const[podcast, setPodcast] = useState<Podcast>({
        id: '',
        title: '',
        description: '',
        dateCreated: new Date(),
        category: ''
    });

    const validationSchema = Yup.object({
        title: Yup.string().required('Titul podcastu je povinné pole'),
        description: Yup.string().required('Popis podcastu je povinné pole'),
        category: Yup.string().required('Kategória podcastu je povinné pole')
    });

    useEffect(() => {
        if (id) {
            loadPodcast(id).then(p => {
                setPodcast(p!);
            })
        }
    }, [id, loadPodcast]);

    function handleFormSubmit(podcast: Podcast) {
        if (podcast.id.length > 0) {
            updatePodcast(podcast).then(() => {
                history.push(`/podcasts/${podcast.id}`);
            });
        } else {
            let newPodcast: Podcast = {
                ...podcast,
                id: uuid()
            }
            createPodcast(newPodcast).then(() => {
                history.push(`/podcasts/${newPodcast.id}`);
            });
        }
    }

    if (!userStore.isLoggedIn) {
        history.push('/');
    }

    if (loadingInitial) {
        return <LoadingComponent content='Nahrávam podcast s databázy ...' />
    }

    return (
        <Segment clearing>
            <Header content='Detail podcastu' sub color='teal' />
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={podcast}
                onSubmit={values => handleFormSubmit(values)}>
                {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                    <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                        <MyTextInput name='title' placeholder='Titul' />
                        <MyTextArea rows={3} placeholder='Popis' name='description' />
                        <MySelectInput options={categoryOptions} placeholder='Kategória' name='category' />
                        {/* <Header content='Autor podcastu' sub color='teal' /> */}
                        <Button as={Link} to='/podcasts' floated='right' type='button' content='Zruš' />
                        <Button
                            disabled={isSubmitting || !dirty || !isValid}
                            loading={isSubmitting}
                            floated='right'
                            positive
                            type='submit'
                            content='Ulož'
                        />
                    </Form>
                )}
            </Formik>

        </Segment>
    )
})
