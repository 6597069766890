import React, { SyntheticEvent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import { Card, Grid, Image, Tab, TabProps } from 'semantic-ui-react';
import { UserPodcast } from '../../app/models/podcast';
import { Link } from 'react-router-dom';

const panes = [
    {menuItem: 'Autor', pane: {key: 'isAuthor'}},
    {menuItem: 'Prihlásené', pane: {key: 'isSubscribed'}},
    {menuItem: 'Neprihlásené', pane: {key: 'isNotSubscribed'}}
];
export default observer(function ProfilePodcasts() {
    const {profileStore, podcastStore} = useStore();
    const {profile} = profileStore;
    const {loadUserPodcasts, loadingUserPodcasts, userPodcasts} = podcastStore;

    useEffect(() => {
        loadUserPodcasts(profile!.userName, 'isAuthor');
    }, [loadUserPodcasts, profile]);

    const handleTabChange = (e: SyntheticEvent, data: TabProps) => {
        loadUserPodcasts(profile!.userName, panes[data.activeIndex as number].pane.key);
    }

    return (
        <Tab.Pane loading={loadingUserPodcasts}>
            <Grid>
                <Grid.Column width={16}>
                    <Tab
                        panes={panes}
                        menu={{secondary: true, pointing: true}}
                        onTabChange={(e, data) => handleTabChange(e, data)}
                    />
                    <br />
                    <Card.Group itemsPerRow={4}>
                        {userPodcasts.map((userPodcast: UserPodcast) => (
                            <Card as={Link} to={`/podcasts/${userPodcast.id}`} key={userPodcast.id}>
                                <Image
                                    src={userPodcast.image || `/assets/categoryImages/${userPodcast.category}.jpg`}
                                    style={{ minHeight: 100, objectFit: 'cover' }}
                                />
                                <Card.Content>
                                    <Card.Header textAlign='center' >{userPodcast.title}</Card.Header>
                                    <Card.Meta textAlign='center'>
                                        <div>{new Date(userPodcast.dateCreated).toLocaleDateString()}</div>
                                        <div>{new Date(userPodcast.dateCreated).toLocaleTimeString()}</div>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                        ))}
                    </Card.Group>
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
})