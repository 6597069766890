import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Container, Dropdown, DropdownMenu, Image, Menu } from 'semantic-ui-react';
import { useStore } from '../stores/store';

function NavBar() {
    const {userStore} = useStore();

    return (
        <Menu inverted fixed='top'>
            <Container>
                <Menu.Item as={NavLink} to='/' exact header>
                    <img src="/assets/logo.png" alt="logo" style={{marginRight: '10px'}} />
                    Vzdelávanie
                </Menu.Item>
                <Menu.Item as={NavLink} to='/podcasts' name='podcasts'>Podcasty</Menu.Item>
                {
                    userStore.isAdmin
                        ?
                        <>
                            <Menu.Item as={NavLink} to='/errors' name='errors'>Chyby</Menu.Item>
                            <Menu.Item>
                                <Button as={NavLink} to='/createPodcast' positive>Nový podcast</Button>
                            </Menu.Item>
                        </>
                        : null
                }
                {userStore.isLoggedIn && (
                    <Menu.Item position='right'>
                        <Image src={userStore.user?.image || '/assets/user.png'} avatar spaced='right' />
                        <Dropdown pointing='top left' text={userStore.user?.userName} >
                            <DropdownMenu>
                                <Dropdown.Item as={Link} to={`/profiles/${userStore.user?.userName}`} text='Môj profil' icon='user' />
                                <Dropdown.Item onClick={userStore.logout} text='Odhlásenie' icon='power' />
                            </DropdownMenu>
                        </Dropdown>
                    </Menu.Item>
                )}

            </Container>
        </Menu>
    )
}

export default observer(NavBar);