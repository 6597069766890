import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Grid, Loader } from 'semantic-ui-react';
import { history } from '../../..';
import { PagingParams } from '../../../app/models/pagination';
import { useStore } from '../../../app/stores/store';
import EmailVerifyForm from '../../users/EmailVerifyForm';
import PodcastFilters from './PodcastFilters';
import PodcastList from './PodcastList';
import PodcastListItemPlaceholder from './PodcastListItemPlaceholder';

function PodcastDashboard() {
    const {podcastStore, userStore} = useStore();
    const {loadPodcasts, podcastRegistry, setPagingParams, pagination} = podcastStore;
    const [loadingNext, setLoadingNext] = useState(false);

    function handleGetNext() {
        setLoadingNext(true);
        setPagingParams(new PagingParams(pagination!.currentPage + 1));
        loadPodcasts().then(() => setLoadingNext(false));
    }

    useEffect(() => {
        if (podcastRegistry.size <= 1) {
            loadPodcasts();
        }
    }, [loadPodcasts, podcastRegistry]);

    if (!userStore.isLoggedIn) {
        history.push('/');
    }

    if (userStore.isLoggedIn && !userStore.isVerified) {
        return (
            <Grid>
                <Grid.Column width='3' />
                <Grid.Column width='10'>
                    <EmailVerifyForm />
                </Grid.Column>
                <Grid.Column width='3' />
            </Grid>
        )
    }

    return (
        <Grid>
            <Grid.Column width='10'>
                {podcastStore.loadingInitial && !loadingNext ? (
                    <>
                        <PodcastListItemPlaceholder />
                        <PodcastListItemPlaceholder />
                        <PodcastListItemPlaceholder />
                    </>
                ) : (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleGetNext}
                        hasMore={!loadingNext && !!pagination && pagination.currentPage < pagination.totalPages}
                        initialLoad={false}
                    >
                        <PodcastList />
                    </InfiniteScroll>
                )}
            </Grid.Column>
            <Grid.Column width='6'>
                <PodcastFilters />
            </Grid.Column>
            <Grid.Column width={10}>
                <Loader active={loadingNext} />
            </Grid.Column>
        </Grid>
    )
}

export default observer(PodcastDashboard);