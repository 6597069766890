import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Icon, Item, Label, Segment } from 'semantic-ui-react';
import { Podcast } from '../../../app/models/podcast';
import { Link } from 'react-router-dom';
import PodcastListItemSubscriber from './PodcastListItemSubscriber';

interface Props {
    podcast: Podcast
}

export default observer(function PodcastListItem({podcast}: Props) {
    return (
        <Segment.Group>
            <Segment>
                {/* {podcast.isRetired &&
                    <Label attached='top' color='red' content='Podcat neaktívny' style={{textAlign: 'center'}} />
                } */}
                <Item.Group>
                    <Item>
                        <Item.Image style={{marginBottom:6}} size="tiny" circular
                            src={podcast.authorProfile?.image || '/assets/user.png'} />
                        <Item.Content>
                            <Item.Header as={Link} to={`/podcasts/${podcast.id}`}>
                                {podcast.title}
                            </Item.Header>
                            <Item.Description>
                                Autor podcastu: <Link to={`/profiles/${podcast.author}`}>{podcast.authorProfile?.displayName}</Link>
                            </Item.Description>
                            {podcast.isAuthor && (
                                <Item.Description>
                                    <Label basic color='orange'>Si autorom tohoto podcastu</Label>
                                </Item.Description>
                            )}
                            {podcast.isSubscribed && !podcast.isAuthor && (
                                <Item.Description>
                                    <Label basic color='green'>Nech sa páči, môžeš počúvať tento podcast</Label>
                                    <Icon name="smile outline" />
                                </Item.Description>
                            )}
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment>
                <span>
                    <Icon name="clock" />{new Date(podcast.dateCreated).toLocaleDateString("sk-SK")}
                </span>
            </Segment>
            <Segment secondary>
                <PodcastListItemSubscriber subscribers={podcast.subscribers!} />
            </Segment>
            <Segment clearing>
                <span>{podcast.description}</span>
                <Button
                    as={Link}
                    to={`/podcasts/${podcast.id}`}
                    floated='right'
                    color='teal'
                    content='Zobraz' />
            </Segment>
        </Segment.Group>
    )
})