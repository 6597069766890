import React from 'react'
import { observer } from 'mobx-react-lite';
import { Container, Header, Segment, Image, Button, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useStore } from '../../app/stores/store';
import LoginForm from '../users/LoginForm';
import RegisterForm from '../users/RegisterForm';
import FacebookLogin from '@greatsumini/react-facebook-login';

function HomePage() {
    const {userStore, modalStore} = useStore();

    return (
        <Segment inverted textAlign='center' vertical className='masthead'>
            <Container text>
                <Header as='h1' inverted>
                    <Image size='massive' src='/assets/logo.png' alt='logo' style={{marginBottom: 12}} />
                    Vzdelávanie
                </Header>
                <Image src='/assets/logo_erasmus_plus_sk.jpg' />
                <Header as='h6' inverted content='VZDELÁVACIA PLATFORMA  je vyvinutá ako súčasť projektu 2021-1-SK02-KA210-YOU-000027445, spolufinancovaného z programu Erasmus+ Európskej únie. Za obsah výlučne zodpovedá organizácia Zvieratá a ľudia navzájom. o.z. Národná agentúra ani Európska komisia nepreberajú žiadnu zodpovednosť za informácie uvedené na týchto stránkach.' />
                {userStore.isLoggedIn ? (
                    <>
                        <Header as='h2' inverted content={'Vitaj na našej vzdelávacej stránke ' + userStore.user!.displayName} />
                        <Button as={Link} to='/podcasts' size='huge' inverted>
                            Hurá na počúvanie
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={() => modalStore.openModal(<LoginForm />)} size='huge' inverted>
                            Prihlásenie
                        </Button>
                        <Button onClick={() => modalStore.openModal(<RegisterForm />)} size='huge' inverted>
                            Registrácia
                        </Button>
                        <Divider horizontal inverted>
                            alebo sa prihlás cez
                        </Divider>
                        <Button
                            as={FacebookLogin}
                            appId="648522263418337"
                            size='huge'
                            inverted
                            color='facebook'
                            content='Facebook'
                            loading={userStore.fbLoading}
                            onSuccess={(response: any) => {
                                userStore.facebookLogin(response.accessToken);
                            }}
                            onFail={(error: any) => {
                                console.log('Login Failed!', error);
                            }}
                            // onProfileSuccess={(response: any) => {
                            //     console.log('Get Profile Success!', response);
                            // }}
                        />
                    </>
                )}
            </Container>
        </Segment>
    )
}

export default observer(HomePage);