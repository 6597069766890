import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite'
import {Segment, Header, Comment, Loader} from 'semantic-ui-react'
import { useStore } from '../../../app/stores/store';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, FieldProps } from 'formik';
import * as Yup from 'yup';

interface Props {
    podcastId: string;
}

export default observer (function PodcastDetailedChat({podcastId} : Props) {
    const {commentStore} = useStore();

    useEffect(() => {
        if (podcastId) {
            commentStore.createHubConnection(podcastId);
        }

        return () => {
            commentStore.clearComments();
        }
    }, [commentStore, podcastId]);

    return (
        <>
        <Segment
            textAlign='center'
            attached='top'
            inverted
            color='teal'
            style={{border: 'none'}}
        >
            <Header>Pridaj svoj postreh k podcastu</Header>
        </Segment>
        <Segment attached clearing>
            <Formik
                onSubmit={(values, { resetForm }) =>
                    commentStore.sendComment(values.body).then(() => resetForm())}
                initialValues={{ body: '' }}
                validationSchema={Yup.object({
                    body: Yup.string().required('Zadaj svoj postreh pred odoslaním')
                })}
            >
                {({ isSubmitting, isValid, handleSubmit }) => (
                    <Form className='ui form'>
                        <Field name='body'>
                            {(props: FieldProps) => (
                                <div style={{ position: 'relative' }}>
                                    <Loader active={isSubmitting} />
                                    <textarea
                                        placeholder='Zadaj svoj postreh (Stlač Enter pre odoslanie, SHIFT + Enter pre nový riadok)'
                                        rows={2}
                                        {...props.field}
                                        onKeyPress={e => {
                                            if (e.key === 'Enter' && e.shiftKey) {
                                                return;
                                            }
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault();
                                                isValid && handleSubmit();
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </Field>
                    </Form>
                )}
            </Formik>
            <Comment.Group>
                    {commentStore.comments.map(comment => (
                        <Comment key={comment.id}>
                            <Comment.Avatar src={comment.image || '/assets/user.png'} />
                            <Comment.Content>
                                <Comment.Author as={Link} to={`/profiles/${comment.userName}`}>{comment.displayName}</Comment.Author>
                                <Comment.Metadata>
                                    <div>{new Date(comment.createdAt).toLocaleDateString("sk-SK")}{' '}{new Date(comment.createdAt).toLocaleTimeString("sk-SK")}</div>
                                </Comment.Metadata>
                                <Comment.Text style={{whiteSpace: 'pre-wrap'}}>{comment.body}</Comment.Text>
                            </Comment.Content>
                        </Comment>
                    ))}
            </Comment.Group>
        </Segment>
    </>
    )
})