import React from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { Button, Header } from 'semantic-ui-react';
import MyTextInput from '../../app/common/form/MyTextInput';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import ValidationErrors from '../errors/ValidationErrors';

export default observer(function LoginForm() {
    const {userStore} = useStore();
    return (
        <Formik
            initialValues={{email: '', password:'', error: null}}
            onSubmit={(values, {setErrors}) => userStore.login(values).catch(error => {
                setErrors({error: error});
            })}
            validationSchema={Yup.object({
                email: Yup.string().required('Zadaj emailovú adresu').email('Zadaj platnú emailovú adresu'),
                password: Yup.string().required('Zadaj heslo')
            })}
        >
            {({handleSubmit, isValid, dirty, isSubmitting, errors}) => (
                <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Prihlás sa na Kurzy' color='teal' textAlign='center' />
                    <MyTextInput name='email' placeholder='Email' type='email' />
                    <MyTextInput name='password' placeholder='Heslo' type='password' />
                    <ErrorMessage
                        name='error' render={() =>
                            <ValidationErrors errors={errors.error} />}
                    />
                    <Button
                        disabled={!isValid || !dirty || isSubmitting}
                        loading={isSubmitting} positive content='Prihlásenie' type='submit' fluid />
                </Form>
            )}
        </Formik>
    )
})