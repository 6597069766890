import React from 'react';
import { Segment, List, Label, Item, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Podcast } from '../../../app/models/podcast';

interface Props {
    podcast: Podcast;
}

export default observer(function PodcastDetailedSidebar({podcast: {subscribers, authorProfile}}: Props) {
    if (!subscribers) {
        return null;
    }
    return (
        <>
        <Segment
            textAlign='center'
            style={{ border: 'none' }}
            attached='top'
            secondary
            inverted
            color='teal'
        >
            {subscribers.length} počúvajúci
        </Segment>
        <Segment attached>
            <List relaxed divided>
                {subscribers.map(subscriber => (
                    <Item style={{ position: 'relative' }} key={subscriber.userName}>
                        {subscriber.userName === authorProfile?.userName && (
                            <Label
                                style={{ position: 'absolute' }}
                                color='orange'
                                ribbon='right'
                            >
                                Autor
                            </Label>
                        )}

                        <Image size='tiny' src={subscriber.image || '/assets/user.png'} />
                        <Item.Content verticalAlign='middle'>
                            <Item.Header as='h3'>
                                <Link to={`/profiles/${subscriber.userName}`}>{subscriber.displayName}</Link>
                            </Item.Header>
                            {/* <Item.Extra style={{ color: 'orange' }}>Following</Item.Extra> */}
                        </Item.Content>
                    </Item>
                ))}
            </List>
        </Segment>
    </>
    )
})