import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { BioProfile, Photo, Profile, ProfileSubscriptions } from "../models/profile";
import { store } from "./store";

export default class ProfileStore {
    profile: Profile | null = null;
    profileSubscriptions: ProfileSubscriptions | null = null;
    bioProfile: BioProfile | null = null;
    loadingProfile: boolean = false;
    uploading: boolean = false;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    get isCurrentUser() {
        if (store.userStore.user && this.profile) {
            return store.userStore.user.userName === this.profile.userName;
        }

        return false;
    }

    setLoadingProfile = (value: boolean) => {
        this.loadingProfile = value;
    }

    setProfile = (profile: Profile | null) => {
        this.profile = profile;
        this.setBioProfile(profile);
        this.setProfileSubscriptions(profile);
    }

    setBioProfile = (profile: Profile | null) => {
        if (profile) {
            this.bioProfile = {
                displayName: profile.displayName,
                bio: profile.bio
            }
        } else {
            this.bioProfile = null;
        }
    }

    setProfileSubscriptions = (profile: Profile | null) => {
        let subscribedTo: number = 0;
        let notSubscribedTo: number = 0;
        let asAuthor: number = 0;
        if (profile) {
            store.podcastStore.podcastRegistry.forEach(podcast => {
                if (podcast.author === profile.userName) {
                    asAuthor++;
                }
                if (podcast.subscribers) {
                    const subscriber = podcast.subscribers.find(subscriber => subscriber.userName === profile.userName)
                    subscriber ? subscribedTo++ : notSubscribedTo++;
                } else {
                    notSubscribedTo++;
                }
            })

            this.profileSubscriptions = {
                subscribed: subscribedTo,
                notSubscribed: notSubscribedTo,
                author: asAuthor
            }
        }
    }

    setUploading = (value: boolean) => {
        this.uploading = value;
    }

    setLoading = (value: boolean) => {
        this.loading = value;
    }

    addPhotoToProfile = (photo: Photo) => {
        if (this.profile && this.profile.photos && photo) {
            this.profile.photos.push(photo);
            if (photo.isMain) {
                store.userStore.setUserImage(photo.url);
                this.profile.image = photo.url;
            }
        }
    }

    loadProfile = async (username: string) => {
        this.setLoadingProfile(true);
        try {
            this.setProfile(await agent.Profiles.get(username));
        }
        catch (error) {
            console.log(error);
        } finally {
            this.setLoadingProfile(false);
        }
    }

    uploadPhoto = async (file: Blob) => {
        this.setUploading(true);
        try {
            const response = await agent.Profiles.uploadPhoto(file);
            this.addPhotoToProfile(response.data);
        }
        catch (error) {
            console.log(error);
        } finally {
            this.setUploading(false);
        }
    }

    setMainPhoto = async (photo: Photo) => {
        this.setLoading(true);
        try {
            await agent.Profiles.setMainPhoto(photo.id);
            store.userStore.setUserImage(photo.url);
            runInAction(() => {
                if (this.profile && this.profile.photos) {
                    let tmpPhoto = this.profile.photos.find(ph => ph.isMain);
                    if (tmpPhoto) {
                        tmpPhoto.isMain = false;
                    }
                    tmpPhoto = this.profile.photos.find(ph => ph.id === photo.id);
                    if (tmpPhoto) {
                        tmpPhoto.isMain = true;
                    }
                    this.profile.image = photo.url;
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setLoading(false);
        }
    }

    deletePhoto = async (photo: Photo) => {
        this.setLoading(true);
        try {
            await agent.Profiles.deletePhoto(photo.id);
            runInAction(() => {
                if (this.profile && this.profile.photos) {
                    this.profile.photos = this.profile.photos.filter(ph => ph.id !== photo.id);
                }
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateBioProfile = async (bioProfile: BioProfile) => {
        this.setLoading(true);
        try {
            await agent.Profiles.updateBioProfle(bioProfile);
            runInAction(() => {
                store.userStore.setDisplayName(bioProfile.displayName);
                if (this.profile) {
                    this.profile.displayName = bioProfile.displayName;
                    this.profile.bio = bioProfile.bio;
                }

                this.setBioProfile(this.profile);
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setLoading(false);
        }
    }
}