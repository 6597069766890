import React from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { Button, Header } from 'semantic-ui-react';
import MyTextInput from '../../app/common/form/MyTextInput';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import ValidationErrors from '../errors/ValidationErrors';

export default observer(function RegisterForm() {
    const {userStore} = useStore();
    return (
        <Formik
            initialValues={{email: '', password:'', userName: '', displayName: '', error: null}}
            onSubmit={(values, {setErrors}) => userStore.registerUser(values).catch(error => {
                setErrors({error: error});
            })}
            validationSchema={Yup.object({
                email: Yup.string().required('Zadaj emailovú adresu').email('Zadaj platnú emailovú adresu'),
                password: Yup.string().required('Zadaj heslo'),
                displayName: Yup.string().required('Zadaj celé meno').min(8, 'Zadaj celé meno, apsoň na 8 znakov'),
                userName: Yup.string().required('Zadaj prezývku').min(3, 'Zadaj prezývku aspoň na 3 znaky'),
            })}
        >
            {({handleSubmit, isSubmitting, errors, isValid, dirty}) => (
                <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Zaregistruj sa do Podcastov' color='teal' textAlign='center' />
                    <MyTextInput name='email' placeholder='Email' type='email' />
                    <MyTextInput name='password' placeholder='Heslo' type='password' />
                    <MyTextInput name='displayName' placeholder='Celé meno' />
                    <MyTextInput name='userName' placeholder='Prezývka'  />
                    <ErrorMessage
                        name='error' render={() =>
                            <ValidationErrors errors={errors.error} />}
                    />
                    <Button disabled={!isValid || !dirty || isSubmitting}
                        loading={isSubmitting} positive content='Registrácia'
                        type='submit' fluid />
                </Form>
            )}
        </Formik>
    )
})