import { makeAutoObservable } from "mobx";
import { history } from "../..";
import agent from "../api/agent";
import { EmailVerificationValues, User, UserFormValues } from "../models/user";
import { store } from "./store";
import { Buffer } from 'buffer';

export default class UserStore {
    user: User | null = null;
    fbLoading: boolean = false;
    refreshTokenTimeout: any;

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        return !!this.user;
    }

    get isVerified() {
        if (this.user) {
            return this.user.isVerified;
        }

        return false;
    }

    get isAdmin() {
        if (this.user) {
            return this.user.isAdmin;
        }

        return false;
    }

    setUser = (user: User | null) => {
        if (user) {
            store.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user.token);
        } else {
            store.commonStore.setToken(null);
            this.stopRefreshTokenTimer();
        }

        this.user = user;
    }

    login = async (userFormValues: UserFormValues) => {
        try {
            const user = await agent.Account.login(userFormValues);
            this.setUser(user);
            history.push('/podcasts');
            store.modalStore.closeModal();
        } catch (error) {
            throw(error);
        }
    }

    logout = () => {
        this.setUser(null);
        history.push('/');
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            this.setUser(user);
        } catch (error) {
            throw(error);
        }
    }

    registerUser = async (userFormValues: UserFormValues) => {
        try {
            await agent.Account.register(userFormValues);
            history.push(`/account/registerSuccess?email=${userFormValues.email}`);
            store.modalStore.closeModal();
        } catch (error) {
            throw(error);
        }
    }

    verifyUser = async (verificationCode: EmailVerificationValues) => {
        try {
            const user = await agent.Account.verify(verificationCode);
            this.setUser(user);
            history.push('/podcasts');
            store.modalStore.closeModal();
        } catch (error) {
            throw(error);
        }
    }

    setUserImage = (photo: string) => {
        if (this.user && photo) {
            this.user.image = photo;
        }
    }

    setDisplayName = (displayName: string) => {
        if (this.user) {
            this.user.displayName = displayName;
        }
    }

    setFbLoading = (value: boolean) => {
        this.fbLoading = value;
    }

    facebookLogin = async (accessToken: string) => {
        this.setFbLoading(true);
        try {
            const user: User = await agent.Account.fbLogin(accessToken);
            this.setUser(user);
            history.push('/podcasts');
        } catch (error) {
            console.log(error);
        } finally {
            this.setFbLoading(false);
        }
    }

    private startRefreshTokenTimer(token: string) {
        const jwtToken = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('ascii'));
        const expires: Date = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (30 * 1000);
        this.refreshTokenTimeout = setTimeout(this.getUser, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}