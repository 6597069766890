import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { PaginatedResults, Pagination, PagingParams } from "../models/pagination";
import { Podcast, UserPodcast } from "../models/podcast";
import { User } from "../models/user";
import { store } from "./store";

export default class PodcastStore {
    podcastRegistry: Map<string, Podcast> = new Map<string, Podcast>();
    selectedPodcast: Podcast | undefined = undefined;
    editMode: boolean = false;
    loading: boolean = false;
    loadingInitial: boolean = false;
    pagination: Pagination | null = null;
    pagingParams: PagingParams = new PagingParams();
    predicateMap: Map<string, boolean> = new Map().set('all', true);
    predicate: string = 'all';
    userPodcasts: UserPodcast[] = [];
    loadingUserPodcasts: boolean = false;
    userPredicate: string = 'isAuthor';


    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.predicate,
            () => {
                this.pagingParams = new PagingParams();
                this.podcastRegistry.clear();
                this.loadPodcasts();
            }
        );
    }

    get axiosParams(): URLSearchParams {
        const params: URLSearchParams = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString());
        if (this.predicate === 'isAuthor' || this.predicate === 'isSubscribed' || this.predicate === 'isNotSubscribed') {
            params.append(this.predicate, 'true');
        }

        return params;
    }

    get userAxiosParams(): URLSearchParams {
        const params: URLSearchParams = new URLSearchParams();
        if (this.userPredicate === 'isSubscribed' || this.userPredicate === 'isNotSubscribed') {
            params.append(this.userPredicate, 'true');
        } else {
            params.append('isAuthor', 'true');
        }

        return params;
    }

    get podcastsByCategory() {
        return Array.from(this.podcastRegistry.values())
            .sort((a: Podcast, b: Podcast) => {
                if (a.category > b.category) {
                    return 1;
                }

                if (b.category > a.category) {
                    return -1;
                }

                return 0;
            });
    }

    get groupedPodcasts() {
        return Object.entries(
            this.podcastsByCategory.reduce((podcasts, podcast) => {
                const category: string = podcast.category;
                podcasts[category] = podcasts[category] ? [...podcasts[category], podcast] : [podcast];
                return podcasts;
            }, {} as {[key: string]: Podcast[]})
        );
    }

    private setLoadingInitial(targetState: boolean) {
        this.loadingInitial = targetState;
    }

    private setSelectedPodcast = (podcast: Podcast) => {
        this.setExtendedPodcastProperties(podcast);
        this.selectedPodcast = podcast;
    }

    private stripPodcast = (podcast: Podcast) => {
        const strippedPodcast: Podcast = {
            id: podcast.id,
            title: podcast.title,
            description: podcast.description,
            dateCreated: podcast.dateCreated,
            category: podcast.category,
        };

        return strippedPodcast;
    }

    private setExtendedPodcastProperties = (podcast: Podcast) => {
        const user: User | null = store.userStore.user;
        if (user) {
            podcast.isSubscribed = podcast.subscribers?.some(
                subscriber => subscriber.userName === user.userName
            );
            podcast.isAuthor = podcast.author === user.userName;
            podcast.authorProfile = podcast.subscribers?.find(
                subscriber => subscriber.userName === podcast.author
            );
        }
    }

    private setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPredicate = (predicate: string) => {
        this.predicate = predicate;
    }

    private setUserPredicate = (predicate: string) => {
        this.userPredicate = predicate;
    }

    private setLoadingUserPodcast = (value: boolean) => {
        this.loadingUserPodcasts = value;
    }

    loadPodcasts = async () => {
        this.loading = true;
        this.loadingInitial = true;
        try {
            const paginatedResults: PaginatedResults<Podcast[]> = await agent.Podcasts.list(this.axiosParams);
            runInAction(() => {
                paginatedResults.data.forEach(podcast => {
                    this.setExtendedPodcastProperties(podcast);
                    this.podcastRegistry.set(podcast.id, podcast)
                });

                this.setPagination(paginatedResults.pagination);
                this.setLoadingInitial(false);
                this.loading = false;
            });

        } catch(error) {
            console.log(error);
            runInAction(() => {
                this.setLoadingInitial(false);
                this.loading = false;
            });
        }
    }

    loadUserPodcasts = async (userName: string, predicate: string) => {
        this.setLoadingUserPodcast(true);
        this.setUserPredicate(predicate);
        try {
            const userPodcasts: UserPodcast[] = await agent.Podcasts.listUserPodcasts(userName, this.userAxiosParams);
            runInAction(() => {
                this.userPodcasts = userPodcasts;
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setLoadingUserPodcast(false);
        }
    }

    loadPodcast = async (podcastId: string) => {
        let podcast: Podcast | undefined = this.podcastRegistry.get(podcastId);
        if (podcast) {
            this.setSelectedPodcast(podcast);
            return podcast;
        } else {
            this.loadingInitial = true;
            try {
                podcast = await agent.Podcasts.details(podcastId);
                this.setLoadingInitial(false);
                this.podcastRegistry.set(podcast.id, podcast);
                this.setSelectedPodcast(podcast);
                return podcast;
            } catch(error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    reloadPodcast = async (podcastId: string) => {
        try {
            let podcast: Podcast = await agent.Podcasts.details(podcastId);
            if (podcast) {
                runInAction(() => {
                    this.podcastRegistry.set(podcast.id, podcast);
                })
                if (podcastId === this.selectedPodcast?.id) {
                    this.setSelectedPodcast(podcast);
                } else {
                    this.setExtendedPodcastProperties(podcast);
                }
            }
        } catch(error) {
            console.log(error);
        }
    }

   createPodcast = async (podcast: Podcast) => {
        try {
            await agent.Podcasts.create(this.stripPodcast(podcast));
            await this.reloadPodcast(podcast.id);
            this.setSelectedPodcast(podcast);
        } catch (error) {
            console.log(error);
        }
    }

    updatePodcast = async (podcast: Podcast) => {
        try {
            await agent.Podcasts.update(podcast.id, this.stripPodcast(podcast));
            await this.reloadPodcast(podcast.id);
            this.setSelectedPodcast(podcast);
        } catch (error) {
            console.log(error);
        }
    }

    deletePodcast = async (podcastId: string) => {
        this.loading = true;
        try {
            await agent.Podcasts.delete(podcastId);
            runInAction(() => {
                this.podcastRegistry.delete(podcastId);
            })
        } catch(error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateSubscription = async (podcastId: string) => {
        this.loading = true;
        try {
            await agent.Podcasts.subscribe(podcastId);
            await this.reloadPodcast(podcastId);
        } catch(error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    clearSelectedPodcast = () => {
        this.selectedPodcast = undefined;
    }
}