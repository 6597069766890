import React from 'react';
import { observer } from 'mobx-react-lite';
import {Button, Header, Item, Segment, Image} from 'semantic-ui-react'
import { Podcast } from '../../../app/models/podcast';
import { Link } from 'react-router-dom';
import { useStore } from '../../../app/stores/store';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const podcastImageStyle = {
    filter: 'brightness(30%)'
};

const podcastImageTextStyle = {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    width: '100%',
    height: 'auto',
    color: 'white'
};

interface Props {
    podcast: Podcast
}

export default observer (function PodcastDetailedHeader({podcast}: Props) {
    const {podcastStore: {updateSubscription, loading}} = useStore();
    const {userStore: {isAdmin}} = useStore();

    return (
        <Segment.Group>
            <Segment basic attached='top' style={{padding: '0'}}>
                {/* {podcast.isRetired &&
                    <Label style={{position: 'absolute', zindex: 1000, left: -14, top: 20}}
                        ribbon color='red' content='Podcast je neaktívny' />
                } */}
                <Image src={podcast.image || `/assets/categoryImages/${podcast.category}.jpg`} fluid style={podcastImageStyle}/>
                <Segment style={podcastImageTextStyle} basic>
                    <Item.Group>
                        <Item>
                            <Item.Content>
                                <Header
                                    size='huge'
                                    content={podcast.title}
                                    style={{color: 'white'}}
                                />
                                <p><br />Podcast pridaný dňa: {new Date(podcast.dateCreated).toLocaleDateString('sk-SK')}</p>
                                <p>
                                    Autor podcastu: <strong> <Link to={`/profiles/${podcast.authorProfile?.userName}`}>{podcast.authorProfile?.displayName}</Link></strong>
                                </p>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Segment>
            </Segment>
            <Segment clearing attached='bottom'>
                {podcast.isAuthor ? (
                    <>
                        <Button
                            as={Link} to={`/managePodcast/${podcast.id}`}
                            color='orange'
                            floated='right'
                        >
                            Uprav Podcast
                        </Button>
                    </>
                ) : podcast.isSubscribed ? (
                    <Button
                        loading={loading}
                        onClick={() => {updateSubscription(podcast.id)}}
                        color='red'
                        disabled={!isAdmin}>
                        Zruš prihlásenie
                        </Button>
                ) : (
                    <Button
                        loading={loading}
                        onClick={() => {updateSubscription(podcast.id)}}
                        color='teal'
                    >
                        Prihlás sa na počúvanie
                    </Button>
                )}
            </Segment>
            {podcast.isSubscribed && !!podcast.audio && (
                <Segment clearing attached='bottom'>
                    <AudioPlayer
                        src={podcast.audio}
                        preload='metadata'
                        showJumpControls={false}
                    />
                </Segment>
            )}

        </Segment.Group>
    )
})