import React from 'react';
import { observer } from 'mobx-react-lite';
import { Image, List, Popup } from 'semantic-ui-react';
import { Profile } from '../../../app/models/profile';
import { Link } from 'react-router-dom';
import ProfileCard from '../../profiles/ProfileCard';

interface Props {
    subscribers: Profile[];
}

export default observer(function PodcastListItemSubscriber({subscribers}: Props) {
    return (
        <List horizontal>
            {subscribers.map((subscriber) => (
                <Popup
                    hoverable
                    key={subscriber.userName}
                    trigger={
                        <List.Item key={subscriber.userName} as={Link} to={`/profiles/${subscriber.userName}`}>
                            <Image size='mini' circular src={subscriber.image || '/assets/user.png'} />
                        </List.Item>
                    }
                >
                    <Popup.Content>
                        <ProfileCard profile={subscriber} />
                    </Popup.Content>
                </Popup>

            ))}
        </List>
    )
})