import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, GridColumn } from 'semantic-ui-react';
import { history } from '../../..';
import LoadingComponent from '../../../app/layouts/LoadingComponent';
import { useStore } from '../../../app/stores/store';
import PodcastDetailedChat from './PodcastDetailedChat';
import PodcastDetailedHeader from './PodcastDetailedHeader';
import PodcastDetailedInfo from './PodcastDetailedInfo';
import PodcastDetailedSidebar from './PodcastDetailedSidebar';

function PodcastDetails() {
    const {podcastStore, userStore} = useStore();
    const {selectedPodcast, loadPodcast, loadingInitial, clearSelectedPodcast} = podcastStore;
    const {id} = useParams<{id: string}>();

    useEffect(() => {
        if (id) {
            loadPodcast(id);
        }
        return () => clearSelectedPodcast();
    }, [id, loadPodcast, clearSelectedPodcast]);

    if (!userStore.isLoggedIn) {
        history.push('/');
    }

    if (loadingInitial || !selectedPodcast) {
        return <LoadingComponent content='Sťahujem detaily podcastu ...' />
    }

    return (
        <>
            <Grid>
                <GridColumn width={10}>
                    <PodcastDetailedHeader podcast={selectedPodcast} />
                    <PodcastDetailedInfo podcast={selectedPodcast} />
                    <PodcastDetailedChat podcastId={selectedPodcast.id} />
                </GridColumn>
                <GridColumn width={6}>
                    <PodcastDetailedSidebar podcast={selectedPodcast} />
                </GridColumn>
            </Grid>
        </>
    )
}

export default observer(PodcastDetails);