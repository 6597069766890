import React from 'react';
import { toast } from 'react-toastify';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import agent from '../../app/api/agent';
import useQuery from '../../app/util/hooks';

export default function RegisterSuccess() {
    const email = useQuery().get('email') as string;

    function handleResendLink() {
        agent.Account.resendLink(email).then(() => {
            toast.success('Email s verifikačným linkom zaslaný - prosím skontroluj svoju emailovú schránku');
        }).catch((error) => {
            const errArr: string[] = error as string[];
            if (errArr) {
                toast.error(errArr[0])
            }
        });
    }

    return (
        <Segment placeholder textAlign='center'>
            <Header icon color='green'>
                <Icon name='check' />
                Registrácia bola úspešná
            </Header>
            <p>Prosím, skontroluj svoju emailovú schránku (vrátane spamu)</p>
            {email &&
                <>
                    <p>Ak si nedostal email, skús si ho poslať znovu</p>
                    <Button primary onClick={handleResendLink} content='Preposlať email znovu' size='huge' />
                </>
            }
        </Segment>
    )
}