import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { history } from '../..';
import { PaginatedResults } from '../models/pagination';
import { Podcast, UserPodcast } from '../models/podcast';
import { BioProfile, Photo, Profile } from '../models/profile';
import { EmailVerificationValues, User, UserFormValues } from '../models/user';
import { store } from '../stores/store';

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token) {
        if (config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }

    return config;
});

axios.interceptors.response.use(async (response) => {
    if (process.env.NODE_ENV === 'development') {
        await sleep(100 + Math.random() * 400);
    }
    // console.log('from axios middleware on success, data:', response.data, ', headers: ', response.headers)
    const pagination = response.headers['pagination'];
    if (pagination) {
        response.data = new PaginatedResults(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResults<any>>;
    }

    return response;
}, (error) => {
    const {data, status, config, headers} = error.response!;
    switch(status) {
        case 400:
            if (typeof(data) === 'string') {
                toast.error(data);
            }
            if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                history.push('/not-found');
            }
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key])
                    }
                }
                throw modalStateErrors.flat();
            }
            break;
        case 401:
            // console.log('from axios middleware on 401, data:', data, ', headers: ', headers)
            if ((headers['www-authenticate'] as string) && (headers['www-authenticate'] as string).startsWith('Bearer error="invalid_token"')) {
                store.userStore.logout();
                toast.error('Session expirovala, prihlás sa znova');
                break;
            }

            if (typeof(data) === 'string') {
                store.userStore.logout();
                toast.error('Neautorizovaný prístup');
                break;
            }

            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key])
                    }
                }
                store.userStore.logout();
                throw modalStateErrors.flat();
            } else {
                store.userStore.logout();
                toast.error('Neautorizovaný prístup');
            }
            break;
        case 404:
            history.push('/not-found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            history.push('/server-error');
            break;
    }
    return Promise.reject(error);
});

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    delete: <T> (url: string) => axios.delete<T>(url).then(responseBody)
}

const Podcasts = {
    list: (params: URLSearchParams) => axios.get<PaginatedResults<Podcast[]>>('/podcasts', {params}).then(responseBody),
    details: (podcastId: string) => requests.get<Podcast>(`podcasts/${podcastId}`),
    create: (podcast: Podcast) => requests.post<void>('/podcasts', podcast),
    update: (podcastId: string, podcast: Podcast) => requests.put<void>(`podcasts/${podcastId}`, podcast),
    delete: (podcastId: string) => requests.delete<void>(`/podcasts/${podcastId}`),
    subscribe: (podcastId: string) => requests.post<void>(`/podcasts/${podcastId}/subscribe`, {}),
    listUserPodcasts: (username: string, params: URLSearchParams) => axios.get<UserPodcast[]>(`/podcasts/${username}/profile`, {params}).then(responseBody)
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<void>('/account/register', user),
    verify: (verificationCode: EmailVerificationValues) => requests.post<User>('account/verify', verificationCode),
    fbLogin: (accessToken: string) => requests.post<User>(`/account/fbLogin?accessToken=${accessToken}`, {}),
    verifyEmail: (token: string, email: string) => requests.post<void>(`/account/verifyEmail?token=${token}&email=${email}`, {}),
    resendLink: (email: string) => requests.get<void>(`/account/resendVerificationEmailLink?email=${email}`)
}

const Profiles = {
    get: (username: string) => requests.get<Profile>(`/profiles/${username}`),
    uploadPhoto: (file: Blob) => {
        let formData = new FormData();
        formData.append('File', file);
        return axios.post<Photo>('/photos', formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    setMainPhoto: (photoId: string) => requests.post<void>(`/photos/${photoId}`,{}),
    deletePhoto: (photoId: string) => requests.delete<void>(`/photos/${photoId}`),
    updateBioProfle: (bioProfile: BioProfile) => requests.put('/profiles', bioProfile)
}

const agent = {
    Podcasts,
    Account,
    Profiles
}

export default agent;