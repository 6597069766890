import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { Header, Item, Segment } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/store';
import Category from './Category';
import PodcastListItem from './PodcastListItem';

function PodcastList() {
    const {podcastStore} = useStore();
    const {groupedPodcasts} = podcastStore;
    
    return (
        <>
            {groupedPodcasts.map(([category, podcasts]) => (
                <Fragment key={category}>
                    <Header sub color='teal'>
                        <Category categoryName={category} size='4x' color='teal' />
                    </Header>
                    <Segment>
                        <Item.Group divided>
                            {podcasts.map(podcast => (
                                <PodcastListItem key={podcast.id} podcast={podcast} />
                            ))}
                        </Item.Group>
                    </Segment>                    
                </Fragment>
            ))}
        </>
    );
}

export default observer(PodcastList);