import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { history } from '../..';
import LoadingComponent from '../../app/layouts/LoadingComponent';
import { useStore } from '../../app/stores/store';
import ProfileContent from './ProfileContent';
import ProfileHeader from './ProfileHeader';

export default observer(function ProfilePage() {
    const {username} = useParams<{username: string}>();
    const {profileStore, userStore} = useStore();
    const {loadProfile, loadingProfile, profile, profileSubscriptions} = profileStore;

    useEffect(() => {
        loadProfile(username);
    }, [loadProfile, username]);

    if (!userStore.isLoggedIn) {
        history.push('/');
    }

    if (loadingProfile) {
        return <LoadingComponent content='Nahrávam profil z databázy...' />
    }

    return (
        <Grid>
            <Grid.Column width={16}>
                {profile &&
                    <>
                    <ProfileHeader profile={profile} profileSubscriptions={profileSubscriptions} />
                    <ProfileContent profile={profile}/>
                    </>
                }
            </Grid.Column>
        </Grid>
    )
})