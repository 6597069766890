import React, { useEffect, useState } from "react";
import { Button, Grid, Header } from "semantic-ui-react";
import PhotoWidgetCropper from "./PhotoWidgetCropper";
import PhotoWidgetDropZone from "./PhotoWidgetDropZone";

interface Props {
    handlePhotoUpload: (file: Blob) => void;
    uploading: boolean;
}

export default function PhotoUploadWidget({handlePhotoUpload, uploading}: Props) {
    const [files, setFiles] = useState<any>([]);
    const [cropper, setCropper] = useState<Cropper>();

    function onCrop() {
        if (cropper) {
            cropper.getCroppedCanvas().toBlob(blob => handlePhotoUpload(blob!));
        }
    }

    useEffect(() => {
        return () => {
            if (files.length > 0) {
                files.forEach((file: any) => {
                    URL.revokeObjectURL(file.preview)
                });
            }
        }
    }, [files])

    return (
        <Grid>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
                <Header sub color='teal' content='Krok 1 - Pridaj obrázok' />
                <PhotoWidgetDropZone setFiles={setFiles} />
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
                <Header sub color='teal' content='Krok 2 - Uprav veľkosť' />
                {files && files.length > 0 && (
                    <PhotoWidgetCropper setCropper={setCropper} imagePreview={files[0].preview} />
                )}
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
                <Header sub color='teal' content='Krok 3 - Náhľad & Nahratie' />
                {files && files.length > 0 &&
                <>
                    <div className='image-preview' style={{minHeight: 200, overflow:'hidden'}} />
                    <Button.Group widths={2}>
                        <Button loading={uploading} onClick={onCrop} positive icon='check' />
                        <Button disabled={uploading} onClick={() => setFiles([])} icon='close' />
                    </Button.Group>
                </>}
            </Grid.Column>
            <Grid.Column width={1} />
        </Grid>
    )
}