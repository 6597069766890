import React from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { Button, Header } from 'semantic-ui-react';
import MyTextInput from '../../app/common/form/MyTextInput';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import ValidationErrors from '../errors/ValidationErrors';

export default observer(function EmailVerifyForm() {
    const {userStore} = useStore();
    return (
        <Formik
            initialValues={{verificationCode: '', error: null}}
            onSubmit={(values, {setErrors}) => userStore.verifyUser(values).catch(error => {
                setErrors({error: error});
            })}
            validationSchema={Yup.object({
                verificationCode: Yup.string().required('Zadaj verifikačný kód z mailu')
            })}
        >
            {({handleSubmit, isValid, dirty, isSubmitting, errors}) => (
                <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Verifikuj svoj email' color='teal' textAlign='center' />
                    <MyTextInput name='verificationCode' placeholder='Verifikačný kód' />
                    <ErrorMessage
                        name='error' render={() =>
                            <ValidationErrors errors={errors.error} />}
                    />
                    <Button
                        disabled={!isValid || !dirty || isSubmitting}
                        loading={isSubmitting} positive content='Verifikuj'
                        type='submit' fluid />
                </Form>
            )}
        </Formik>
    )
})