import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import agent from "../../app/api/agent";
import { useStore } from "../../app/stores/store";
import useQuery from "../../app/util/hooks";
import LoginForm from "./LoginForm";

export default function ConfirmEmail() {
    const {modalStore} = useStore();
    const email = useQuery().get('email') as string;
    const token = useQuery().get('token') as string;

    const Status = {
        Veifying: 'Verifikácia prebieha',
        Failed: 'Verifikácia neúspšná',
        Success: 'Verifikácia úspešná'
    }

    const [status, setStatus] = useState(Status.Veifying);

    function handleResendLink() {
        agent.Account.resendLink(email).then(() => {
            toast.success('Email s verifikačným linkom zaslaný = prosím skontroluj svoju emailovú schránku');
        }).catch(error => console.log(error));
    }

    useEffect(() => {
        agent.Account.verifyEmail(token, email).then(() => {
            setStatus(Status.Success);
        }).catch(() => {
            setStatus(Status.Failed);
        })
    }, [Status.Success, Status.Failed, email, token])

    function getBody() {
        switch (status) {
            case Status.Veifying:
                return <p>Verifikácia prebieha ...</p>;
            case Status.Failed:
                return (
                    <div>
                        <p>Verifikácia neúspešná. Skús si zaslať verifikačný link na emailovú adresu znovu</p>
                        <Button primary onClick={handleResendLink} size='huge' content='Preposlať email znovu' />
                    </div>
                )
            case Status.Success:
                return (
                    <div>
                        <p>Verifikácia bola úspešná, môžeš sa teraz prihlásiť</p>
                        <Button primary onClick={() => modalStore.openModal(<LoginForm />)} size='huge' content='Prihlásenie' />
                    </div>
                )
        }
    }

    return (
        <Segment placeholder textAlign='center'>
            <Header icon>
                <Icon name='envelope' />
                Verifikácia emailovej adresy
            </Header>
            <Segment.Inline>
                {getBody()}
            </Segment.Inline>
        </Segment>
    )
}