import { observer } from 'mobx-react-lite';
import React from 'react';
import { Header, Menu } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/store';

export default observer(function PodcastFilters() {
    const {podcastStore: {predicate, setPredicate}} = useStore();

    return (
        <Menu vertical size='large' style={{width: '100%', marginTop: 60 }}>
            <Header icon='filter' attached color='teal' content='Výber podcastov' />
            <Menu.Item
                content='Všetky'
                active={predicate==='all'}
                onClick={() => setPredicate('all')}
            />
            <Menu.Item
                content='Na ktoré som prihlásený/prihlásená'
                active={predicate==='isSubscribed'}
                onClick={() => setPredicate('isSubscribed')}
            />
            <Menu.Item
                content='Na ktoré sa možem prihlásiť'
                active={predicate==='isNotSubscribed'}
                onClick={() => setPredicate('isNotSubscribed')}
            />
            <Menu.Item
                content='U ktorých som autorom/autorkou'
                active={predicate==='isAuthor'}
                onClick={() => setPredicate('isAuthor')}
            />
        </Menu>
    )
})