import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Button, Grid, Header, Tab } from "semantic-ui-react";
import ProfileEditForm from "./ProfileEditForm";

export default observer(function ProfileAbout() {
    const {profileStore: {isCurrentUser, bioProfile}} = useStore();
    const [editMode, setEditMode] = useState(false);

    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16}>
                    <Header floated='left' icon='user' content={`${bioProfile?.displayName} - základné info` } />
                    {isCurrentUser && (
                        <Button
                            floated='right'
                            basic
                            content={editMode ? 'Zruš' : 'Uprav profil'}
                            onClick={() => setEditMode(!editMode)}
                        />
                    )}
                </Grid.Column>
                <Grid.Column width={16}>
                    {editMode ?
                        <ProfileEditForm setEditMode={setEditMode} /> :
                        <span style={{whiteSpace: 'pre-wrap'}}>{bioProfile?.bio}</span>
                    }
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    )
})