import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDog, faCow, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faYarn } from '@fortawesome/free-brands-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
    categoryName: string;
    size: string;
    color: string;
}

export default function Category({categoryName, size, color}: Props) {
    let fontSize: SizeProp = '1x';
    switch(size) {
        case "1x":
            fontSize = '1x'
            break;
        case "2x":
            fontSize = '2x'
            break;
        case "3x":
            fontSize = '3x'
            break;
        case "4x":
            fontSize = '4x'
            break;
        case "5x":
            fontSize = '5x'
            break;
        case "6x":
            fontSize = '6x'
            break;
        case "7x":
            fontSize = '7x'
            break;
        case "8x":
            fontSize = '8x'
            break;
        case "9x":
            fontSize = '9x'
            break;
        case "10x":
            fontSize = '10x'
            break;
        default:
            fontSize = '1x'
    }

    if (categoryName === 'dogs') {
        return (
            <FontAwesomeIcon icon={faDog} size={fontSize} color={color} />
        );
    }

    if (categoryName === 'farm_animals') {
        return (
            <FontAwesomeIcon icon={faCow} size={fontSize} color={color} />
        );
    }

    if (categoryName === 'weaving') {
        return (
            <FontAwesomeIcon icon={faYarn} size={fontSize} color={color} />
        );
    }

    return (
        <FontAwesomeIcon icon={faQuestionCircle} size={fontSize} color={color} />
    );
}